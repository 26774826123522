import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import AvatarImage from '@shared/ui/display/AvatarImage';
import { ReactComponent as LocationIcon } from '@icons/wolfkit-light/map-pin-light.svg';
import { ReactComponent as CalendarIcon } from '@icons/wolfkit-light/calendar-light.svg';
import { formatProfileJoinedDate } from '@utils/DateTime';
import Icon from '@shared/ui/icons/Icon';
import IconText from '@shared/ui/icons/IconText';
import SocialsUtil from '@utils/socials';
import IconButton from '@shared/ui/buttons/Button/IconButton';
import { ContainerRow, ContainerColumn } from '@components/styled';
import { Subtitle, Title } from '@components/styled/Typography';
import VerificationIcon from '@shared/ui/icons/VerificationIcon';
import TraderBadge from '@shared/ui/badges/TraderBadge';
import ProfileDescription from './ProfileDescription';
const Container = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
}));
const InfoContainer = styled(ContainerColumn)((props) => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const UsernameContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    gap: `${props.theme.spacing_sizes.xs * 5}px`,
}));
// TODO: replace hardcoded path to img with url from profile
const AvatarContainer = styled.div(props => ({
    maxWidth: '128px',
    maxHeight: '128px',
    minWidth: '128px',
    minHeight: '128px',
    borderRadius: '50%',
    // background: "url('/avatars/Avatar-4.png') lightgray center / cover no-repeat",
    marginRight: `${props.theme.spacing_sizes.xs * 2.75}px`,
}));
const Socials = styled(ContainerRow)(props => ({
    height: 'auto',
    width: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const Nickname = styled(Subtitle)(() => ({
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: 'normal',
}));
const LocationJoinDate = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    lineHeight: `${props.theme.spacing_sizes.l}px`,
    gap: props.theme.spacing_sizes.l,
}));
const DisplayNameContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.s,
    alignItems: 'center',
}));
const DisplayName = styled(Title)(() => ({
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: 'normal',
}));
const ProfilePreview = ({ profile, className = undefined, }) => {
    var _a, _b;
    const { t, i18n } = useTranslation();
    const socials = useMemo(() => SocialsUtil.getSocials(t), [t]);
    const nickname = (profile === null || profile === void 0 ? void 0 : profile.username) ? `@${profile.username}` : '';
    const name = (profile === null || profile === void 0 ? void 0 : profile.displayName) || nickname;
    const getSocialIcon = (type) => {
        if (socials[type]) {
            return socials[type].smallIcon;
        }
        return () => undefined;
    };
    const joinedDate = () => {
        try {
            const date = new Date(profile.createdAt);
            return formatProfileJoinedDate(date, i18n.language);
        }
        catch (ex) {
            return '_';
        }
    };
    const location = useMemo(() => {
        let res;
        if (!profile.location)
            return res;
        const countriesList = t('profile.countries.countries_list', { ns: 'translation', returnObjects: true });
        return countriesList[profile.location] || '';
    }, [profile, t]);
    return (_jsxs(Container, { className: className, children: [_jsx(AvatarContainer, { children: _jsx(AvatarImage, { publicName: profile === null || profile === void 0 ? void 0 : profile.displayName, imageUrl: (_a = profile === null || profile === void 0 ? void 0 : profile.profilePicture) === null || _a === void 0 ? void 0 : _a.avatar, size: 'medium' }) }), _jsxs(InfoContainer, { children: [_jsxs(UsernameContainer, { children: [_jsxs(DisplayNameContainer, { children: [_jsx(DisplayName, { children: name }), profile.type === 'TRADER' && (_jsxs(_Fragment, { children: [_jsx(VerificationIcon, { verified: Boolean(profile.isVerifiedTrader), size: 24 }), _jsx(TraderBadge, { size: 'small', textSize: 12, textHeight: '18px' })] }))] }), _jsx(Socials, { children: (_b = profile.contacts) === null || _b === void 0 ? void 0 : _b.map((social, idx) => (_jsx("a", { target: '_blank', rel: 'noopener noreferrer', href: social.contact, children: _jsx(IconButton, { variant: 'tinted', color: 'primary-light', size: 'small', IconComponent: getSocialIcon(social.type) }) }, idx))) })] }), !!(profile === null || profile === void 0 ? void 0 : profile.displayName) && (_jsx(Nickname, { children: nickname })), _jsxs(LocationJoinDate, { children: [location && (_jsx(IconText, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: LocationIcon })), text: location })), _jsx(IconText, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: CalendarIcon })), text: `${t('profile.joined')} ${joinedDate()}` })] }), _jsx(ProfileDescription, { profileId: profile.id, text: profile.description })] })] }));
};
export default ProfilePreview;
